<script lang="ts">
	import Modal from "src/components/common/modal.svelte";
	import { UpdateTerm } from "src/graphql-operations";
	import { formatName } from "src/helpers/format-name";
	import { handleToggleModal } from "src/helpers/handle-toggle-modal";
	import {
		defaultErrorMessage,
		defaultSuccessMessage,
	} from "src/services/alert";
	import userStore from "src/stores/user";

	export let modal: Modal;
	export let mentorModal: Modal;
	export let photo: string;
	export let name: string;

	let inputTerm = true;

	async function updateTerm() {
		if (!inputTerm) {
			return defaultErrorMessage(
				"Você precisa aceitar os termos para continuar!",
			);
		}
		const { id, email } = await userStore.getUser();

		await UpdateTerm({
			variables: {
				id,
				email,
				last_term_accepted: new Date().toISOString(),
			},
			refetchQueries: ["GetUser", "GetUserCore"],
		}).then(() => {
			defaultSuccessMessage("Termo atualizado com sucesso!");
			handleToggleModal(modal, mentorModal);
		});
	}
</script>

<Modal
	bind:this={modal}
	bgClass="!opacity-80"
	disabled
	outerClass="w-full h-full flex flex-col justify-center gap-10 md:gap-5 md:w-auto md:h-auto md:rounded-[37px] bg-white items-center px-5 sm:px-14 py-5 sm:py-10 2xl:p-14 md:max-w-[600px]"
	roundedNone
>
	<div class="flex flex-col items-center gap-3">
		<img
			class="size-[88px] min-h-[88px] rounded-full object-cover"
			alt=""
			src={photo}
		/>
		<h1
			class="text-center text-3xl font-bold leading-[40px] text-gray-brand-500 sm:text-5xl"
		>
			Olá, <span class="text-think-500">{formatName(name)}!</span>
		</h1>
		<h2 class="text-center text-lg font-bold text-neutral-700">
			NOVO TERMO DE MENTORIA ADICIONADO!
		</h2>
	</div>

	<div class="mx-auto flex items-center justify-between gap-5">
		<input
			id="input-term"
			class="size-6 rounded-md"
			type="checkbox"
			bind:checked={inputTerm}
		/>
		<label
			class="text-justify text-lg font-semibold leading-6 text-neutral-700"
			for="input-term"
		>
			Como mentor, reconheço ter lido e concordado integralmente com os novos
			<a
				class="text-think-500"
				href="https://txm.app.br/lgpd/mentor"
				target="_blank"
			>
				termos
			</a>
			propostos para as mentorias.
		</label>
	</div>
	<button
		class="button self-end font-semibold text-think-500"
		disabled={!inputTerm}
		on:click={updateTerm}
	>
		Continuar
	</button>
</Modal>
