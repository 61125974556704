<script lang="ts">
	import { goto } from "$app/navigation";
	import Modal from "src/components/common/modal.svelte";
	import { tv } from "tailwind-variants";

	export let url: string;
	export let modal: Modal;
	export let title: string;
	export let description: string;
	export let disabled: boolean = false;
	export let type: "platform" | "mentoring" | "profile" = "platform";

	const formatType = {
		platform: "Plataforma",
		mentoring: "Mentorias",
		profile: "Perfil",
	};

	const titleStyles = tv({
		base: "text-think-500",
		variants: {
			disabled: {
				true: "text-think-100",
			},
		},
	});

	const descriptionStyles = tv({
		base: "text-neutral-900",
		variants: {
			disabled: {
				true: "text-neutral-500",
			},
		},
	});

	const goToLink = (url) => {
		if (disabled) return;
		if (!url) return modal.close();

		goto(url);
	};
</script>

<div class="flex flex-col gap-[10px]">
	<div class={titleStyles({ disabled })}>
		{#if type === "platform"}
			<svg
				width="30"
				height="30"
				viewBox="0 0 30 30"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect x="5" y="7" width="6" height="4" rx="1" fill="currentColor" />
				<rect x="12" y="7" width="6" height="4" rx="1" fill="currentColor" />
				<rect x="19" y="7" width="6" height="4" rx="1" fill="currentColor" />
				<path
					d="M27 0H3C1.35 0 0 1.5 0 3.33333V21.6667C0 23.5 1.35 25 3 25H7.5L6 26.6667V30H24V26.6667L22.5 25H27C28.65 25 30 23.5 30 21.6667V3.33333C30 1.5 28.65 0 27 0ZM27 21.6667H3V3.33333H27V21.6667Z"
					fill="currentColor"
				/>
			</svg>
		{:else if type === "mentoring"}
			<svg
				width="30"
				height="30"
				viewBox="0 0 30 30"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M15 27C21.6 27 27 21.6 27 15C27 8.4 21.6 3 15 3C8.4 3 3 8.4 3 15C3 21.6 8.4 27 15 27ZM15 0C23.25 0 30 6.75 30 15C30 23.25 23.25 30 15 30C6.75 30 0 23.25 0 15C0 6.75 6.75 0 15 0ZM22.5 14.25V16.5H13.5V7.5H15.75V14.25H22.5Z"
					fill="currentColor"
				/>
			</svg>
		{:else if type === "profile"}
			<svg
				width="30"
				height="28"
				viewBox="0 0 30 28"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M28.8832 5.76473L23.7456 0.952358C23.0751 0.362397 22.1965 0.0238854 21.2769 0.00121672C20.3572 -0.021452 19.4608 0.273304 18.7581 0.829415L1.8829 16.6365C1.27683 17.209 0.899461 17.9593 0.814139 18.7616L0.00788062 26.0856C-0.0173779 26.3428 0.0182576 26.6021 0.112247 26.845C0.206235 27.0878 0.356263 27.3083 0.551637 27.4906C0.726839 27.6534 0.934621 27.7822 1.16307 27.8696C1.39152 27.957 1.63614 28.0013 1.8829 28H2.05165L9.87049 27.3326C10.727 27.2526 11.5281 26.8992 12.1393 26.3314L29.0145 10.5244C29.6694 9.87626 30.0234 9.01133 29.9988 8.11916C29.9742 7.22699 29.573 6.38033 28.8832 5.76473ZM22.5081 11.6309L17.4831 6.92391L21.1394 3.41123L26.2582 8.20604L22.5081 11.6309Z"
					fill="currentColor"
				/>
			</svg>
		{/if}
	</div>
	<div class="flex flex-col justify-between gap-2 sm:flex-row sm:items-center">
		<div class="flex w-full flex-col md:w-2/3">
			<h3 class={titleStyles({ disabled })}>
				{title}
			</h3>
			<p class={descriptionStyles({ disabled })}>
				{description}
			</p>
		</div>
		<button
			on:click={() => goToLink(url)}
			class="button min-w-[180px] text-think-500 sm:hidden md:block"
			{disabled}
		>
			Acessar {formatType[type]}
		</button>
		<button
			on:click={() => goToLink(url)}
			class="button hidden min-w-[180px] text-think-500 sm:block md:hidden"
			{disabled}
		>
			Acessar
		</button>
	</div>
</div>
