<script lang="ts">
	import OutputsSearchbar from "./outputs-searchbar.svelte";
	import Spinner from "src/components/common/spinner.svelte";
	import { GetSectionsBasic } from "src/graphql-operations";
	import userStore from "src/stores/user";
	import SectionDropdown from "./section-dropdown.svelte";

	const sections = GetSectionsBasic({
		variables: { team_id: userStore.getSelectedProjectId() },
	});
</script>

<div class="flex h-full w-full">
	<div class="flex w-full flex-col">
		<span class="py-3 text-2xl font-semibold text-neutral-700">
			Metodologia
		</span>

		<div class="flex w-full flex-col justify-between md:flex-row">
			<div class="flex flex-col md:w-1/2">
				<span class="py-3 text-4xl font-bold text-neutral-800">
					Qual etapa do <span class="text-think-500">seu negócio</span>
					você quer desenvolver hoje?
				</span>
				<span class="text-lg text-neutral-700">
					Caso não saiba por onde começar, consulte nossa metodologia completa
					para conhecer todo o material da TXM Business.
				</span>
			</div>
			<a href="/methodology">
				<button class="button mt-4 self-start text-think-500 md:mt-0">
					Ver Metodologia
				</button>
			</a>
		</div>

		<div class="my-3 w-1/3">
			<OutputsSearchbar />
		</div>

		<div class="mt-4 flex w-full justify-center">
			{#if $sections.loading}
				<Spinner color="neutral-700" size="12rem" />
			{:else if $sections.data}
				<div
					class="flex w-full flex-col justify-center space-y-3 lg:flex-row lg:space-y-0"
				>
					{#each $sections.data.sections as section}
						<SectionDropdown {section} />
					{/each}
				</div>
			{/if}
		</div>
	</div>
</div>
