<script lang="ts">
	import { page } from "$app/stores";
	import Modal from "src/components/common/modal.svelte";
	import { mentorsCPF } from "src/constants/projects/gamification-v2/mentors-white-list";
	import { Roles_Enum } from "src/graphql-operations";
	import userStore from "src/stores/user";
	import { onMount } from "svelte";
	import Footer from "../../footer.svelte";
	import Header from "../../header.svelte";
	import Navbar from "../../navbar.svelte";
	import Events from "./events/events.svelte";
	import MentorModal from "./modals/mentor-modal.svelte";
	import TermModal from "./modals/term-modal.svelte";
	import Progress from "./progress/progress.svelte";
	import SectionsNav from "./sections-nav/sections-nav.svelte";
	import Videos from "./videos/videos.svelte";

	let cpf = "";
	let name = "";
	let photo = "";
	let termAccepted;
	let termModal: Modal;
	let mentorModal: Modal;
	let ableToAccess = false;

	$: if (ableToAccess && termAccepted) {
		mentorModal.open();
	} else if (ableToAccess && !termAccepted) {
		termModal.open();
	}

	$: host = $page.url.hostname;
	$: isInovaseed = host && host.includes("inovaseed");

	onMount(() => {
		userStore
			.getUser()
			.then(
				({
					role,
					cpf: usercpf,
					names,
					profile_picture,
					last_term_accepted,
				}) => {
					ableToAccess =
						mentorsCPF.includes(usercpf) || role === Roles_Enum.Mentor;

					if (ableToAccess) {
						cpf = usercpf;
						name = names[0]?.name;
						photo = profile_picture?.path ?? "/img/user/no-avatar.svg";
						termAccepted =
							new Date(last_term_accepted) >= new Date("2024-01-31");

						if (!termAccepted) termModal && termModal.open();
						else {
							mentorModal.open();
						}
					}
				},
			);
	});
</script>

<div class="default-content-width">
	<Header />
	<Navbar />
	<Progress />

	<div class="mt-20">
		<Events />
	</div>

	{#if !isInovaseed}
		<div class="mt-20">
			<Videos />
		</div>
	{/if}

	<div class="mt-20">
		<SectionsNav />
	</div>
	<footer>
		<Footer />
	</footer>
</div>

<TermModal {name} {photo} bind:modal={termModal} {mentorModal} />

<MentorModal {name} {cpf} {photo} bind:modal={mentorModal} />
