<script lang="ts">
	import userStore from "src/stores/user";

	async function login() {
		await userStore.loginWithPopup();
	}

	function message() {
		console.warn("TODO: Message");
	}
</script>

<div class="mt-10 h-full w-full">
	<div
		class="default-content-width mx-auto flex flex-col-reverse items-center lg:flex-row"
	>
		<div class="flex flex-col p-10 lg:w-2/3">
			<div class="container items-center lg:items-start lg:p-10">
				<h1 class="title text-xl lg:text-3xl">Já faz parte da TXM Methods?</h1>
				<button class="button text-think-500" on:click={login}
					>Fazer seu Login</button
				>
			</div>

			<div class="container items-center lg:items-start lg:p-10">
				<h1 class="title text-xl lg:text-3xl">Quer fazer parte?</h1>
				<button class="button text-think-500" on:click={message}
					>Mande uma Mensagem</button
				>
			</div>
		</div>

		<img class="w-1/4" alt="Smartphone" src="/img/index/smartphone.png" />
	</div>
</div>

<style>
	.container {
		@apply flex w-full flex-col;
	}
	.title {
		@apply py-4 font-semibold text-neutral-700;
	}
</style>
