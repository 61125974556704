<script>
	import { page } from "$app/stores";
	import IndexFooter from "src/components/indexFooter.svelte";
	import { hosts } from "src/constants/common/hosts";
	import Header from "./header.svelte";
	import Hero from "./hero.svelte";
	import Identificate from "./identificate.svelte";
	import IntroCard from "./intro-card.svelte";
	import Testimonials from "./testimonials.svelte";

	$: hostname = $page.url.hostname;
	$: host = hosts.find((host) => hostname.includes(host.hostname));
</script>

<div class="relative w-full overflow-hidden">
	<Header {host} />
	<Hero {host} />
	<IntroCard {host} />
	{#if !host}
		<Testimonials />
		<Identificate />
	{/if}
	<IndexFooter {host} />
</div>
