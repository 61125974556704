<script lang="ts">
	import Spinner from "src/components/common/spinner.svelte";
	import { GetSectionsBasic } from "src/graphql-operations";
	import userStore from "src/stores/user";
	import SectionProgressCard from "src/components/common/content/section-progress-card.svelte";

	const sections = GetSectionsBasic({
		variables: { team_id: userStore.getSelectedProjectId() },
	});
</script>

<div class="flex w-full justify-center">
	{#if $sections.loading}
		<Spinner color="neutral-700" size="12rem" />
	{:else if $sections.data}
		<div
			class="flex w-full flex-col justify-center space-y-5 lg:flex-row lg:space-x-5 lg:space-y-0"
		>
			{#each $sections.data.sections as section}
				<div class="h-[234px] lg:w-1/3">
					<SectionProgressCard orientation="ROW" {section} />
				</div>
			{/each}
		</div>
	{/if}
</div>
