<script>
	import userStore from "src/stores/user";
	import Home from "src/components/index/home/home.svelte";
	import Overview from "src/components/index/overview/overview.svelte";
</script>

<svelte:head>
	<title>TXM Business</title>
</svelte:head>

{#await userStore.isAuthenticated() then isAuthed}
	{#if !isAuthed}
		<Home />
	{:else}
		<Overview />
	{/if}
{/await}
