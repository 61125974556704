<script lang="ts">
	import { Host } from "src/constants/common/hosts";

	export let host: Host;
</script>

<div
	class="flex flex-1 flex-col space-y-5 rounded-t-3xl bg-[#0B2465] px-14 py-10"
>
	<div class="flex flex-col">
		<div
			class="flex flex-col items-center justify-evenly space-y-6 md:flex-row md:items-start md:space-y-0"
		>
			<div class=" flex flex-col items-center space-y-8 md:items-start">
				{#if host}
					<a class="w-60 object-cover" href={host.link} target="_blank">
						<img alt={host.name} src={host.img} />
					</a>
				{:else}
					<div>
						<a href="https://www.txm-methods.com/">
							<img alt="" src="/img/index/footer/logo_txm.png" /></a
						>
					</div>
				{/if}
				<div>
					{#if host?.hostname === "inpetuhub"}
						<p class="text-neutral-300">
							Licensed for Mood Lab Design ® 2023 - 2033
						</p>
					{:else}
						<p class="text-neutral-300">
							Copyright © TXM Business - 2019-2029
						</p>
					{/if}
				</div>
			</div>
			{#if !host}
				<div class="flex flex-col items-center space-y-4">
					<p class="text-neutral-300">Contato</p>
					<div class="flex flex-row space-x-4">
						<a href="mailto:contato@txm-methods.com">
							<img alt="" src="/img/index/footer/icon_mail.png" /></a
						>
						<!-- <img src="/img/index/footer/icon_whatsapp.png" alt="" /> -->
					</div>
				</div>
				<div class="flex flex-col items-center space-y-4">
					<p class="text-neutral-300">Redes Sociais</p>
					<div class="flex flex-row space-x-4">
						<a href="https://instagram.com/txm.methods">
							<img alt="" src="/img/index/footer/icon_insta.png" /></a
						>
						<a href="https://www.facebook.com/TXM-Methods-112217004453869">
							<img alt="" src="/img/index/footer/icon_facebook.png" /></a
						>
						<a href="https://br.linkedin.com/company/txm-metodhs-ltd">
							<img alt="" src="/img/index/footer/icon_linkedin.png" />
						</a>
					</div>
				</div>
			{/if}
			<div class="flex flex-col items-center space-y-4">
				<p class="text-neutral-300">Política de Privacidade</p>
				<a href="/lgpd"><img alt="" src="/img/index/footer/icon_page.png" /></a>
			</div>
		</div>
	</div>
</div>
