<script lang="ts">
	import Spinner from "src/components/common/spinner.svelte";
	import {
		CountAllToolsDoneByTeamId,
		GetTeamScore,
		GetToolsByTeamId,
	} from "src/graphql-operations";
	import userStore from "src/stores/user";
	import SectionsProgress from "./sections-progress.svelte";
	import { derived } from "svelte/store";

	let allTools = [];
	let filteredTools;

	const score = GetTeamScore({
		variables: { team_id: userStore.getSelectedProjectId() },
	});

	const getAllToolsDone = derived(
		CountAllToolsDoneByTeamId({
			variables: { team_id: userStore.getSelectedProjectId() },
		}),
		($completedTools) => {
			if (!$completedTools.loading && $completedTools.data) {
				return $completedTools.data.questionnaires_teams_states;
			}
		},
	);

	const tools = GetToolsByTeamId({
		variables: { team_id: userStore.getSelectedProjectId() },
	});

	$: {
		const { loading, data } = $tools;
		if (!loading && data) {
			data.teams.map((team) => {
				team.groups[0].group.contents[0].content.sections.map((section) => {
					section.section.outputs.map((output) => {
						allTools.push(...output.tools);
						allTools = [...allTools];
					});
				});
			});
		}
	}

	$: {
		const map = new Map();

		allTools.forEach((item) => {
			map.set(item.tool.name, item);
		});

		filteredTools = Array.from(map.values());
	}
</script>

<div class="flex w-full flex-col">
	<div class="flex items-center justify-between">
		<span class="text-2xl font-semibold text-neutral-700"> Projeto </span>

		<a href="/project">
			<button class="button text-think-500"> Ver Projeto </button>
		</a>
	</div>

	<div class="mt-4">
		<SectionsProgress />
	</div>

	<div class="flex w-full flex-row items-center py-4">
		<div class="flex w-full items-center justify-between">
			{#if $score.loading}
				<Spinner color="neutral-700" size="2rem" />
			{:else if $score.data}
				<div class="flex items-center">
					<img class="h-10" alt="Foguete" src="/img/overview/rocket.svg" />
					<span class="ml-2 text-lg font-semibold text-neutral-700">
						{$score.data.tools_aggregate.aggregate.sum.points || "0"} pontos adquiridos
					</span>
				</div>
			{/if}
			{#if $getAllToolsDone && filteredTools?.length > 0}
				<span class="ml-2 text-lg text-neutral-700">
					{Math.floor(
						($getAllToolsDone?.length / filteredTools?.length) * 100,
					)}% concluído
				</span>
			{/if}
		</div>
	</div>
</div>
