<script lang="ts">
	import { Host } from "src/constants/common/hosts";

	export let host: Host;
</script>

<div class="container relative mx-auto">
	<div
		class="hero z-10 flex items-center pb-14 lg:bg-[url('/img/index/hero-bg.svg')] lg:pt-32"
	>
		<div class="max-w-[84rem] p-8">
			<div class="flex flex-col text-center md:text-left">
				<span
					class=" text-xl font-bold leading-tight text-gray-700 md:text-5xl"
				>
					Uma metodologia <span class="text-think-500">exclusiva</span><br />
					aplicada para o desenvolvimento<br />de
					<span class="text-think-500">ideias e negócios.</span>
				</span>
				{#if !host}
					<span class="mt-4 text-lg font-light md:text-2xl">
						Desde a pré-incubação até a aceleração de novas empresas.
					</span>
					<div class="mt-16 hidden flex-row items-center md:flex">
						<img alt="Scroll" src="/img/index/mouse.svg" />
						<h1 class="mx-6 text-2xl font-semibold text-neutral-500">
							Saiba mais sobre a TXM
						</h1>
					</div>
				{/if}
			</div>
		</div>
	</div>

	<div class="absolute left-0 top-0 z-0 hidden h-full w-full lg:block">
		<picture class="absolute right-[-22%] top-[-25%] h-[1000px] w-[1000px]">
			<source srcset="/img/index/hero-bg/blur-cyan.webp" type="image/webp" />
			<source srcset="/img/index/hero-bg/blur-cyan.png" type="image/png" />
			<img
				class="relative h-full w-full"
				src="/img/index/hero-bg/blur-cyan.png"
			/>
		</picture>
		<picture class="absolute right-[-5%] top-[5%] h-[1000px] w-[1000px]">
			<source srcset="/img/index/hero-bg/blur-blue.webp" type="image/webp" />
			<source srcset="/img/index/hero-bg/blur-blue.png" type="image/png" />
			<img
				class="relative h-full w-full"
				src="/img/index/hero-bg/blur-blue.png"
			/>
		</picture>
	</div>
</div>

<style>
	.hero {
		position: relative;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: right;
		width: 100%;
	}

	.blur {
		position: absolute;
		width: 60rem;
		height: 30rem;
		filter: blur(10rem);
	}
</style>
