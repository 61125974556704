<script lang="ts">
	import Spinner from "src/components/common/spinner.svelte";
	import { GetOutputsDoneBySection } from "src/graphql-operations";
	import type { GetSectionsBasicQuery } from "src/graphql-operations";
	import userStore from "src/stores/user";
	import { slide } from "svelte/transition";

	export let section: GetSectionsBasicQuery["sections"][0];

	function handleDropdown() {
		dropdown = !dropdown;
	}

	let dropdown = false;
	const outputs = GetOutputsDoneBySection({
		variables: {
			section_id: section.id,
			team_id: userStore.getSelectedProjectId(),
		},
	});
</script>

{#if $outputs.loading}
	<Spinner color="neutral-700" size="2rem" />
{:else if $outputs.data}
	<div class="mx-1 flex w-full flex-col items-center">
		<!-- Section card -->
		<div
			class="card mb-1 w-full cursor-pointer flex-col p-3"
			on:click={handleDropdown}
		>
			<div class="my-3 flex w-full flex-row items-start justify-between">
				<div class="flex w-[85%] flex-row items-start">
					<figure class="h-[62px] w-[62px] flex-shrink-0">
						<img
							class="max-h-[62px] max-w-[62px]"
							alt={section.name}
							src="/img{section.section_icons_files.find(
								(icon) => icon.type === 'medium',
							).file.path}"
						/>
					</figure>
					<div>
						<span
							style="color: var(--color-{section.slug}-700)"
							class="text-2xl font-semibold"
						>
							{section.name}
						</span>
						<span class="line-clamp-3 text-neutral-700">
							{section.intro}
						</span>
					</div>
				</div>

				{#if !dropdown}
					<svg
						class="mx-4 h-6 w-6 text-neutral-500"
						fill="none"
						stroke="currentColor"
						stroke-width="2"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M19 9l-7 7-7-7"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				{:else}
					<svg
						class="mx-4 h-6 w-6 text-neutral-500"
						fill="none"
						stroke="currentColor"
						stroke-width="2"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M5 15l7-7 7 7"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				{/if}
			</div>

			<div class="mr-3 flex flex-col self-end">
				<span class="mb-1 ml-1 mr-10 text-xs font-bold text-neutral-500">
					{$outputs.data.outputs.filter(
						(o) => o.done.aggregate.count == o.all.aggregate.count,
					).length}
					de
					{$outputs.data.outputs.length}
					Outputs concluídos
				</span>
				<div class="h-[6px] w-full rounded-full bg-[#C4C4C4]">
					<div
						style="width: {($outputs.data.outputs.filter(
							(o) => o.done.aggregate.count == o.all.aggregate.count,
						).length /
							$outputs.data.outputs.length) *
							100}%;
                            background-color: var(--color-{section.slug}-500)"
						class="h-[6px] rounded-full"
					/>
				</div>
			</div>
		</div>

		<!-- Outputs cards -->
		{#each dropdown ? $outputs.data.outputs : [] as output, i}
			<a
				class="w-[95%]"
				href="/{section.slug}/{output.slug}"
				transition:slide|local={{ delay: i * 20 }}
			>
				<div class="card output-card mx-auto my-1 cursor-pointer px-5 py-6">
					<div class="flex w-full flex-row items-center justify-between">
						<div class="flex flex-grow flex-row items-center">
							<div class="h-8 w-8 flex-shrink-0">
								<img
									class="h-full max-w-full"
									alt={output.name}
									src="/img{output.icons.find((icon) => icon.type === 'small')
										.file.path}"
								/>
							</div>
							<div class="ml-4 flex-grow">
								<span
									style="color: var(--color-{section.slug}-700)"
									class="flex flex-row text-xl font-semibold"
								>
									{output.name}

									{#if output.done.aggregate.count == output.all.aggregate.count}
										<svg
											class="h-6 w-6 text-green-500"
											fill="none"
											stroke="currentColor"
											stroke-width="2"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M5 13l4 4L19 7"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
									{/if}
								</span>
								<span
									class="line-clamp-3 text-sm text-neutral-700 lg:hidden xl:line-clamp-2"
								>
									{output.intro}
								</span>
							</div>
						</div>

						<svg
							class="ml-4 h-6 w-6 flex-shrink-0 text-neutral-700"
							fill="none"
							stroke="currentColor"
							stroke-width="2"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M9 5l7 7-7 7"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					</div>
				</div>
			</a>
		{/each}
	</div>
{/if}

<style>
	.card {
		border-radius: 15px;
		box-shadow:
			-4px -5px 13px #ffffff,
			5px 7px 16px rgba(0, 0, 0, 0.25);
	}

	.card:hover {
		box-shadow:
			-4px -5px 8px #ffffff,
			2px 4px 8px rgba(0, 0, 0, 0.25);
	}
</style>
