<script>
	const testimonials = [
		{
			name: "Marcos Buson",
			img: "/img/index/testimonials/buson.jpeg",
			company: "Fundador da HARDS e Sócio da MOA Ventures",
			testimonial:
				"O Cocreation Lab by TXM possui a melhor metodologia de suporte a empreendedores na fase de ideação de negócios do Brasil. O time da TXM juntamente com sua plataforma de controle são fundamentais para qualquer processo de validação e lançamento de novos produtos do mercado. Recomendo, apoio e sou mentor do programa por acreditar no seu impacto no ecossistema de inovação.",
		},
		{
			name: "Felipe Mandawalli",
			img: "/img/index/testimonials/felipe.png",
			company: " ",
			testimonial:
				"Acompanho há anos o trabalho da TXM e é evidente o quanto eles vêm desempenhando um papel fundamental no ecossistema brasileiro de inovação, qualificando empreendedores, introduzindo negócios mais inovadores e competitivos no mercado, de startups à empresas tradicionais repaginadas e mais criativas, por meio de uma metodologia muito consistente e embasada cientificamente, com um DNA próprio que é o grande diferencial para outros hubs de inovação.",
		},
		{
			name: "Odolir Daniel dos Santos Junior",
			img: "/img/index/testimonials/odolir.jpg",
			company: "Exparticipante, mentor e Fundador do You Do",
			testimonial:
				"O Cocreation Lab foi um grande passo e parceiro na história do You Do e na minha como empreendedor. Posso dizer que uma boa parte da minha evolução pessoal se deu pelas mentorias do programa e pela metodologia aplicada. Entrar no Cocreation pode ser a girada de chave que o seu negócio precisa.",
		},
	];

	let currentPage = 0;
	function select(pageNumber) {
		currentPage = pageNumber;
	}
	function prev() {
		if (currentPage > 0) currentPage--;
		else currentPage = testimonials.length - 1;
	}
	function next() {
		if (currentPage < testimonials.length - 1) currentPage++;
		else currentPage = 0;
	}
</script>

<div class="mx-auto mt-24 flex h-full lg:w-9/12">
	<div class="m-auto inline-flex h-full w-full items-center">
		<span class="button-page" on:click={prev}>
			<svg
				class="h-12 lg:w-14"
				fill="none"
				stroke="currentColor"
				stroke-width="2"
				viewBox="0 0 24 24"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M15 19l-7-7 7-7"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</span>

		{#each testimonials as testimonial, i}
			<div
				class="flex h-full w-full flex-col items-center justify-center lg:flex-row {i !=
				currentPage
					? 'hidden'
					: ''}"
			>
				<div class="flex flex-col items-center justify-center lg:w-2/5">
					<img
						class="rounded-full border-4 border-think-500 lg:w-1/2"
						alt={testimonial.name}
						src={testimonial.img}
					/>
					<div class="m-auto inline-flex items-center">
						{#each testimonials as _, j}
							<div
								class="mx-1 my-2 h-3 w-3 rounded-full bg-neutral-400
                                {i == j ? 'selected-page' : ''}"
							/>
						{/each}
					</div>
				</div>

				<div class="flex flex-col lg:w-3/5">
					<span
						class=" text-center text-3xl font-bold text-think-500 lg:text-left"
						>{testimonial.name}</span
					>
					<span class=" mt-4 text-center text-xl font-medium lg:text-left"
						>{testimonial.company}</span
					>
					<span class=" mt-8 text-left text-xl font-semibold lg:text-left"
						>{testimonial.testimonial}</span
					>
				</div>
			</div>
		{/each}

		<span class="button-page" on:click={next}>
			<svg
				class="h-12 lg:w-14"
				fill="none"
				stroke="currentColor"
				stroke-width="2"
				viewBox="0 0 24 24"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M9 5l7 7-7 7" stroke-linecap="round" stroke-linejoin="round" />
			</svg>
		</span>
	</div>
</div>

<style>
	.button-page {
		@apply cursor-pointer px-10 py-8 text-neutral-600;
	}
	.selected-page {
		@apply h-4 w-4 bg-think-500;
	}
</style>
