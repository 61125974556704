<script>
	import YoutubeEmbedCard from "src/components/common/multimedia/youtube-embed-card.svelte";
	import Spinner from "src/components/common/spinner.svelte";
	import { GetVideosByTeamAllContents } from "src/graphql-operations";
	import userStore from "src/stores/user";

	const videos = GetVideosByTeamAllContents({
		variables: {
			limit: 3,
			offset: 0,
			order: "desc",
		},
	});
</script>

<div
	class="flex w-full flex-col items-center justify-between gap-5 md:flex-row"
>
	<div class="flex w-full flex-col md:w-1/2">
		<h1 class="text-2xl font-semibold text-gray-brand-500">Últimos vídeos</h1>
		<span class="mt-3 text-gray-brand-500">
			Confira os vídeos sugeridos para essa semana ou clique em ver todos para
			consultar todos os vídeos da plataforma.
		</span>
	</div>
	<a href="/videos">
		<button class="button text-think-500"> Ver todos os vídeos </button>
	</a>
</div>

{#if !$videos.loading && $videos.data}
	<div
		class="mt-4 grid grid-cols-1 grid-rows-3 gap-4 lg:grid-cols-3 lg:grid-rows-1"
	>
		{#each $videos.data.videos as video}
			<YoutubeEmbedCard
				isCard={true}
				thumbnail={video.thumbnail}
				videoDescription={video.description}
				videoTitle={video.title}
				videoUrl={video.link}
			/>
		{/each}
	</div>
{:else}
	<div class="flex items-center justify-center">
		<Spinner color="neutral-500" size="4rem" />
	</div>
{/if}
