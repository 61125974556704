<script lang="ts">
	import "@fullcalendar/core/vdom.cjs";
	import FullCalendar, { type CalendarOptions } from "svelte-fullcalendar";
	import daygridPlugin from "@fullcalendar/daygrid";
	import listPlugin from "@fullcalendar/list";
	import googleCalendarPlugin from "@fullcalendar/google-calendar";

	// function updateDateIncrement() {
	//     if (currentView == 'listDay')
	//         options = {...options, dateIncrement: {days: 7}}
	//     else
	//         options = {...options, dateIncrement: {months: 1}}
	// }
	function changeView(newView: "listWeek" | "dayGridMonth") {
		const calendar = calendarEl.getAPI();
		calendar.changeView(newView);
		// updateDateIncrement();
		currentView = newView;
	}
	// function previous() {
	//     const calendar = calendarEl.getAPI();
	//     calendar.prev();
	//     title = calendar.view.title;
	// }
	// function next() {
	//     const calendar = calendarEl.getAPI();
	//     calendar.next();
	//     title = calendar.view.title;
	// }
	// function today() {
	//     const calendar = calendarEl.getAPI();
	//     calendar.today();
	//     title = calendar.view.title;
	// }
	// function getDate() {
	//     console.log(calendarEl.getAPI());
	//     let date = calendarEl.getAPI().getDate();
	//     console.log(date);
	//     return date;
	// }

	export let calendarId;
	let calendarEl;
	let currentView = "listWeek";

	// let currentDay = new Date();
	// let sunday = currentDay.getDate() - currentDay.getDay();
	// let week = [...Array(7)].map((_, i) => sunday + i);
	// let month = currentDay.toLocaleString('pt-br', { month: 'long'});
	// let year = currentDay.getFullYear();
	// let title = `${currentDay.getDate()} de ${month} de ${year}`;

	let options: CalendarOptions = {
		initialView: currentView,
		plugins: [daygridPlugin, listPlugin, googleCalendarPlugin],
		googleCalendarApiKey: import.meta.env.VITE_GOOGLE_CALENDAR_API_KEY,
		events: {
			googleCalendarId: calendarId,
		},
		locale: "pt-br",
		noEventsText: "Nada nessa semana!",
		eventColor: "#3151F4",
		buttonText: {
			today: "Hoje",
		},
		aspectRatio: 1,
		// headerToolbar: false,
		// dateIncrement: {days: 7}
	};

	$: options = { ...options, events: { googleCalendarId: calendarId } };
</script>

<div>
	<!-- <div class="flex flex-col mb-8">
        <div class="flex flex-row w-full justify-between mb-4">
            <span class="font-bold text-4xl text-gray-brand-500">
                {title}
            </span>
            <div class="flex flex-row items-center bg-white rounded-md shadow-lg">
                <button class="py-2 px-4 text-neutral-500 border-r-[1px] w-1/2"
                    on:click={previous}>

                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" /></svg>
                </button>

                <button class="py-2 px-4 text-neutral-500 border-l-[1px] w-1/2"
                    on:click={next}>

                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" /></svg>
                </button>
            </div>
        </div>

        <div class="flex flex-row justify-between">
            <button class="bg-white px-4 py-2 text-neutral-500 font-medium rounded-md shadow-lg"
                on:click={today}>

                Hoje
            </button>
            <div class="grid grid-cols-7 gap-0 w-full ml-8">
                {#each week as d, i}
                    <button class="px-6 py-3 bg-[#E3E9EC]
                        {i == 0 ? 'rounded-l-md' : i == 6 ? 'rounded-r-md' : ''}">

                        {d}
                    </button>
                {/each}
            </div>
        </div>
    </div> -->

	<FullCalendar bind:this={calendarEl} {options} />

	<div
		class="selector relative flex -translate-y-2 flex-row items-center justify-center rounded-[15px] bg-[#E3E9EC]"
	>
		<button
			class="calendar-button {currentView == 'dayGridMonth'
				? 'selected-view'
				: ''}"
			on:click={() => changeView("dayGridMonth")}>Calendário</button
		>
		<button
			class="calendar-button {currentView == 'listWeek' ? 'selected-view' : ''}"
			on:click={() => changeView("listWeek")}>Lista</button
		>
	</div>
</div>

<style>
	.calendar-button {
		@apply m-4 w-1/3 rounded-md bg-white px-4 py-2 text-neutral-500 lg:w-1/4;
	}
	.selected-view {
		background: #cfd7ff;
		border-radius: 6px;
		color: #1931ad;
	}
	.selected-day {
		background: #3151f4;
		color: white;
	}

	:global(.fc-view-harness.fc-view-harness-active),
	:global(.fc-list),
	:global(.fc-scroller),
	:global(.fc-daygrid),
	:global(.fc-scrollgrid.fc-scrollgrid-liquid),
	:global(.fc-scroller .fc-list-empty) {
		@apply rounded-t-[15px] border-none;
		background: white;
	}

	:global(.fc-scroller.fc-scroller-liquid) {
		@apply relative;
		padding: 6px;
	}

	:global(.fc-list-day-cushion.fc-cell-shaded),
	:global(.fc-theme-standard td),
	:global(.fc-theme-standard th),
	:global(.fc-theme-standard .fc-list) {
		background: white;
		border: none;
	}

	:global(.fc-list-day-text, .fc-list-day-side-text) {
		@apply font-semibold text-gray-brand-500;
	}

	:global(.fc-list-event-title) {
		@apply font-bold text-think-500;
	}

	:global(.fc-list-event-time) {
		@apply text-gray-brand-500;
	}

	:global(.fc-view-harness.fc-view-harness-active::after),
	:global(.selector::after) {
		@apply rounded-[15px] shadow-lg;
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -1;
	}

	:global(.fc-toolbar-title) {
		@apply font-bold text-gray-brand-500;
	}

	:global(.fc-today-button.fc-button.fc-button-primary) {
		@apply rounded-md border-none bg-white px-4 py-2 font-medium text-neutral-500 shadow-lg;
		@apply transition hover:bg-gray-50 hover:text-neutral-500;
	}

	:global(.fc-next-button.fc-button) {
		@apply rounded-md border-l-[1px] border-none border-neutral-200 bg-white font-medium text-neutral-500 shadow-lg;
		@apply transition hover:bg-gray-50 hover:text-neutral-500;
	}

	:global(.fc-prev-button.fc-button) {
		@apply rounded-md border-r-[1px] border-none border-neutral-200 bg-white font-medium text-neutral-500 shadow-lg;
		@apply transition hover:bg-gray-50 hover:text-neutral-500;
	}

	:global(.fc-view.fc-daygrid) {
		@apply p-5;
	}

	:global(.fc-col-header-cell.fc-day) {
		@apply font-normal capitalize text-neutral-500;
	}

	:global(.fc-daygrid-day.fc-day > .fc-daygrid-day-frame) {
		@apply text-gray-brand-500;
	}
</style>
