<script lang="ts">
	import { Host } from "src/constants/common/hosts";
	import userStore from "src/stores/user";

	export let host: Host;

	async function login() {
		await userStore.loginWithPopup();
	}
</script>

<header class="z-20 w-full lg:absolute">
	<div class="container mx-auto flex flex-row items-center justify-between p-8">
		{#if host}
			<a
				class="max-w-32 object-cover sm:max-w-max"
				href={host.link}
				target="_blank"
			>
				<img alt={host.name} src={host.img} />
			</a>
		{:else}
			<div>
				<a href="https://txm.business/" target="_blank">
					<img alt="" src="/img/index/header/logo_txm.svg" />
				</a>
			</div>
		{/if}
		<button class="button px-10 text-think-500" on:click={login}>Login</button>
	</div>
</header>
